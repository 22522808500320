import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { translateText } from '../config/translation';

const useStyles = makeStyles(theme => ({
  headerImageWrapper: {
    maxWidth: 1400,
    margin: '0 auto',
  },
  faqText: {
    margin: 0,
  },
  textBlock: {
    textAlign: 'justify',
  },
  alignLeft: {
    textAlign: 'left',
  },
  inlinePhotoLeft: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      float: 'left',
      marginRight: 32,
    },
  },
  inlinePhotoRight: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      float: 'right',
      marginLeft: 32,
    },
  },
  imgCaption: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
  },
}));

const Wedding = ({ path, lang }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
  query {
    pscaBanner: file(relativePath: { eq: "images/psca-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    psca: file(relativePath: { eq: "images/psca.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    everybodyhits: file(relativePath: { eq: "images/bcages.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Layout pageName='Wedding' activeRoute={ path } lang={ lang }>
      <SEO title="Wedding" />
      <Box className={ classes.headerImageWrapper }>
        <Img fluid={data.pscaBanner.childImageSharp.fluid} />
      </Box>
      <p className={ classes.imgCaption }>The Philadelphia School of Circus Arts (PSCA)</p>
      <Box m="32px" />
      <Container maxWidth="md" className={ classes.textBlock }>
        <Box>
          <p>{ translateText("We are excited to host our friends and family at our reception on October 12th, 2019 in Philadelphia! Because we have many friends and family in Helio’s home country of Brazil, we will also be hosting a second wedding reception in Sao Paulo, Brazil, in late 2020. Stay tuned for the details!", lang) }</p>
          <p>{ translateText("Our US wedding reception will be held at the Philadelphia School of Circus Arts, a former church that was converted into a circus arts space in 2017. The space lends itself to all sorts of performances; there will certainly be a few circus surprises throughout the evening!", lang) }</p>
          <Box className={ classes.inlinePhotoRight }>
            <Img fluid={data.everybodyhits.childImageSharp.fluid} />
            <p className={ classes.imgCaption }>Everybody Hits</p>
          </Box>
          <p>{ translateText("There is a free parking lot available on-site to all guests. Additionally, the venue is directly next to Upsal Regional Rail Station.", lang) }</p>
          <p>{ translateText("The reception will start with a cocktail hour at 6pm, followed by dinner and dancing.", lang) }</p>
          <p>{ translateText("After the reception, guests are invited to our after-party at Everybody Hits for beer, bites, and batting cages! Transportation will be provided from the Philadelphia School of Circus Arts to Everybody Hits.", lang) }</p>
        </Box>
        <h2 style={{clear: 'both'}}>{ translateText("FAQ", lang) }</h2>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary className={classes.alignLeft} expandIcon={<ExpandMoreIcon />}>
            <p className={classes.faqText}><strong>{ translateText("When should I RSVP by?", lang) }</strong></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <p className={classes.faqText}>{ translateText("September 1st, 2019", lang) }</p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <ExpansionPanelSummary className={classes.alignLeft} expandIcon={<ExpandMoreIcon />}>
            <p className={classes.faqText}><strong>{ translateText("I have a food allergy or restriction. Can I make a special request?", lang) }</strong></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <p className={classes.faqText}>{ translateText("", lang) }We will have options for all dietary restrictions. Please let us know in the allergies section of your RSVP.</p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <ExpansionPanelSummary className={classes.alignLeft} expandIcon={<ExpandMoreIcon />}>
            <p className={classes.faqText}><strong>{ translateText("Can I bring my kids?", lang) }</strong></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <p className={classes.faqText}>{ translateText("Unless noted on your invitation, we are unable to accommodate additional children at our reception.", lang) }</p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <ExpansionPanelSummary className={classes.alignLeft} expandIcon={<ExpandMoreIcon />}>
            <p className={classes.faqText}><strong>{ translateText("What should I wear?", lang) }</strong></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <p className={classes.faqText}>{ translateText("Short answer: Whatever makes you feel fabulous (except an all-white outfit, of course)!", lang) }<br />{ translateText("Long answer: Short dresses, long dresses, cocktail dresses, jumpsuits, casual suits, slacks, button-downs, sports coats, ties or no ties- this is all perfect for our reception.", lang) }</p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary className={classes.alignLeft} expandIcon={<ExpandMoreIcon />}>
            <p className={classes.faqText}><strong>{ translateText("Is there an after party?", lang) }</strong></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box>
              <p className={classes.faqText}>{ translateText("Yes! After the reception, guests are invited to party on at Everybody Hits for beer, bites, and a batting cages! We will be providing transportation to Everybody Hits from the venue around 11:15pm.", lang) }</p>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Container>
    </Layout>
  )
}

export default Wedding
